<template>
  <Account title="Branch admin">
    <AccountAdminUserTable />
  </Account>
</template>

<script>
export default {
  name: 'AccountBranchAdmin',
  components: {
    Account: () => import('./Account'),
    AccountAdminUserTable: () =>
      import('../components/admin/AccountAdminUserTable')
  }
}
</script>
